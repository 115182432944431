const DEFAULT_CAMPAIGN_ID = process.env.GATSBY_DEFAULT_CAMPAIGN_ID;

module.exports = {
    brandInfo: {
        phoneNumber: "18885364210",
        contactEmail: "debtrefresh@carloanscanada.com",
        address: "10 Lower Spadina Ave, Toronto ON",
        copyright: `© ${new Date().getFullYear()} Debt Refresh`,
        defaultCampaignId: DEFAULT_CAMPAIGN_ID || "82",
        disclaimer: "All information collected in this application is required to properly pair you with a Licensed Insolvency Trustee.  This allows us to locate the Trustee closest to you who matches your needs as provided in the application.  You will receive email notification of which company we have paired you with and we will only share your information with this specific Trustee.  The Trustee will then contact you to  provide you with available options.  These options include but are not limited to Bankruptcy, Consumer Proposal and other debt consolidation options.",
    },

    project: "debt-refresh-application",
    name: "Debt Refresh",
    shortName: `DR`,
    title: `Debt Refresh Application`,
    description: `Get the lowest rates on the best lending programs available!`,
    reportingDomain: "carloanscanada.com/debtrefresh",

    siteUrl: "https://www.carloanscanada.com/debtrefresh",
    homeUrl: "https://www.carloanscanada.com/debtrefresh",
    termsUrl: "https://www.carloanscanada.com/terms-of-use",
    privacyPolicyUrl: "https://www.carloanscanada.com/privacy-policy",
    successUrl: "https://www.carloanscanada.com/success",

    hideSuccessCtas: true,
    twilioVerifyMethods: ['sms']
};